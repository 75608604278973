import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import Layout from '../../components/layout/layout';
import ListCard from '../../components/list-card';

function SingleTag({ data, location }) {
  const mergedItems = [
    ...data.programs.nodes.map(i => Object.assign(i, { link: 'program' })),
    ...data.events.nodes.map(i => Object.assign(i, { link: 'event' })),
    ...data.knowledgeCenters.nodes.map(i => Object.assign(i, { link: 'knowledge-center' }))]
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  const pageTitle = `Tag - "${data?.tags?.name}"`;

  return (
    <Layout location={location} pageName={pageTitle}>
      <Container>
        <Grid item xs={12}>
          <Typography variant='h3' className='h5' sx={{ fontWeight: 'bold', marginTop: '40px' }}>{data?.tags?.name}</Typography>
          <Typography variant='headingdividerline' />
        </Grid>
        <Grid container spacing={1} sx={{ padding: '30px 0' }}>
          {
            mergedItems?.map(item => <ListCard details={item} link={`/${item.link}/${item.slug}`} />)
          }
        </Grid>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query SingleTag($contentful_id: String!) {
    tags: contentfulTag(contentful_id: {eq: $contentful_id}) {
      name
      contentful_id
    }
    programs: allContentfulPageProgram(
      filter: {metadata: {tags: {elemMatch: {contentful_id: {eq: $contentful_id}}}}}
      sort: {fields: updatedAt, order: DESC}
    ) {
      nodes {
        contentful_id
        programShortTitle
        slug
        category
        programThumbnail {
          file {
            url
          }
          gatsbyImageData(
            aspectRatio: 1.78
            jpegProgressive: true
            formats: WEBP
            resizingBehavior: SCALE
            placeholder: BLURRED
          )
        }
        createdAt
        updatedAt(formatString: "MMMM DD, YYYY")
      }
    }
    knowledgeCenters: allContentfulPageKnowledge(
      filter: {metadata: {tags: {elemMatch: {contentful_id: {eq: $contentful_id}}}}}
      sort: {fields: updatedAt, order: DESC}
    ) {
      nodes {
        contentful_id
        slug
        category
        createdAt
        updatedAt(formatString: "MMMM DD, YYYY")
        knowledgeThumbnail {
          file {
            url
          }
          gatsbyImageData(
            aspectRatio: 1.78
            jpegProgressive: true
            formats: WEBP
            resizingBehavior: SCALE
            placeholder: BLURRED
          )
        }
        title
      }
    }
    events: allContentfulPageEvent(
      filter: {metadata: {tags: {elemMatch: {contentful_id: {eq: $contentful_id}}}}}
      sort: {fields: updatedAt, order: DESC}
    ) {
      nodes {
        contentful_id
        title
        slug
        category
        createdAt
        updatedAt(formatString: "MMMM DD, YYYY")
        eventShortDescription {
          eventShortDescription
        }
        eventThumbnail {
          file {
            url
          }
          gatsbyImageData(
            aspectRatio: 1.78
            jpegProgressive: true
            formats: WEBP
            resizingBehavior: SCALE
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

SingleTag.propTypes = {
  location: PropTypes.shape.isRequired
};

export default SingleTag;
